<template>
  <!-- <div>系统设置</div> -->
  <el-container class="SystemSettings">
    <el-header>系统设置</el-header>
    <el-main>
      <el-tabs type="activeName" @tab-click="handleClick">
        <el-tab-pane label="实时定位">
          <RealTimePositioningSettings :config="setttingConfig"></RealTimePositioningSettings>
        </el-tab-pane>
        <el-tab-pane label="实时视频">
          <RealTimeVideoSettings :config="setttingConfig"></RealTimeVideoSettings>
        </el-tab-pane>
        <el-tab-pane label="录像回放">
          <VideoPlaybackSettings :config="setttingConfig"></VideoPlaybackSettings>
        </el-tab-pane>
        <el-tab-pane label="轨迹回放">
          <TrackPlaybackSettings :config="setttingConfig"></TrackPlaybackSettings>
        </el-tab-pane>
        <el-tab-pane label="视频轮询">
          <VideoPollingSettings :config="setttingConfig"></VideoPollingSettings>
        </el-tab-pane>
      </el-tabs>
    </el-main>
    <el-footer>
      <el-button @click="onSaveData" type="primary" class="footerButton">保存</el-button>
      <el-button @click="onReset" class="footerButton">重置</el-button>
    </el-footer>
  </el-container>
</template>

<script>

import { mapState, mapActions } from 'vuex';

// 实时定位
import RealTimePositioningSettings from "./RealTimePositioningSettings";
// 实时视频
import RealTimeVideoSettings from "./RealTimeVideoSettings";
// 录像回放
import VideoPlaybackSettings from "./VideoPlaybackSettings";
// 轨迹回放
import TrackPlaybackSettings from "./TrackPlaybackSettings";
// 视频轮询
import VideoPollingSettings from "./VideoPollingSettings";

import { cloneDeep } from 'lodash';

import { sleep } from '@/utils/sleep';


export default {
  // `name`与路由保持一致
  name: 'SystemSettings',
  components: {
    RealTimePositioningSettings,
    RealTimeVideoSettings,
    VideoPlaybackSettings,
    TrackPlaybackSettings,
    VideoPollingSettings,
  },
  data() {
    return {
      setttingConfig: null,
    };
  },
  computed: {
    ...mapState(["userConfig"]),
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const settings = cloneDeep(vm.userConfig);
      vm.setttingConfig = settings;
    });
  },
  methods: {
    ...mapActions(["dispatchUserConfig"]),
    handleClick() {

    },
    // 保存设置
    async onSaveData() {
      try {

        const { setttingConfig } = this;
        const cloneConfig = cloneDeep(setttingConfig);

        const result = await this.dispatchUserConfig(cloneConfig);

        if (result.flag === 1) {
          this.$message.success('修改配置成功');
        }
      } catch (err) {
        console.error(err);
        this.$message.warning('修改失败');
      }

    },
    // 取消设置
    onReset() {
      this.setttingConfig = cloneDeep(this.userConfig);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/style/reportStyle.scss';

.SystemSettings {
  width: 44%;
  height: 80vh;
  margin: 5vh 26%;
  .el-header {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid;
    line-height: 60px;
    color: #1890ff;
    font-size: 16px;
    font-weight: bold;
  }
  .el-main {
    ::v-deep .el-tabs {
      height: 100%;
      .el-tabs__item {
        padding-left: 20px !important;
      }
      .el-tabs__content {
        height: calc(100% - 55px);
      }
    }
  }
  .el-footer {
    background-color: #ffffff;
    border-top: 1px solid #cccccc;
    line-height: 55px;
    padding: 0 50px;
    text-align: right;
    .footerButton {
      width: 90px;
    }
  }
}
</style>