<template>
  <div>
    <Dialog
      :value="visible"
      width="650px"
      height="430px"
      appendToBody
      destroyClose
      okText="保存"
      cancelText="上一步"
      :submit="submitOk"
      @close="cancelClick"
    >
      <!-- 标题 -->
      <template #title>
        <span>指令调试</span>
      </template>

      <div class="mainCls">
        <div class="item">
          <label>设置上报次数</label>
          <el-input-number
            v-model="count"
            :min="0"
            :max="10"
            label="描述文字"
            @change="changeCount"
          ></el-input-number>
          <el-button
            icon="el-icon-plus"
            type="primary"
            plain
            @click="addTime"
            :disabled="disabled"
          >添加上报次数</el-button>
          <span>(最多10次)</span>
        </div>

        <div class="time-content">
          <!-- <div class="item" style="width: 45.3%;">
            <label>第1次</label>
            <el-time-picker
              arrow-control
              v-model="time"
              :picker-options="{
            selectableRange: '00:00:00 - 23:59:59'
          }"
              placeholder="请选择上报时间"
            ></el-time-picker>
          </div>-->
          <div
            class="item"
            v-for="(domain, index) in timeList"
            :key="domain.key"
            :prop="'timeList.' + index + '.value'"
          >
            <label>第{{index + 1}}次</label>
            <el-time-picker
              arrow-control
              v-model="domain.value"
              :picker-options="{
                selectableRange: '00:00:00 - 23:59:59'
              }"
              placeholder="请选择上报时间"
            ></el-time-picker>

            <el-tooltip content="删除" placement="bottom" effect="light">
              <el-button
                class="delete-icon"
                type="primary"
                icon="el-icon-minus"
                circle
                @click="remove(domain)"
              ></el-button>
            </el-tooltip>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog';
import { setSaveSetting } from '@/api/live-monitor-api';
import moment from 'dayjs';

export default {
  name: 'DeviceCommandModal',
  components: {
    Dialog,
  },
  model: {
    prop: 'visible',
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    allSelectIds: { // 选择的车组
      type: Array,
      default: []
    }
  },
  data() {
    return {
      count: 0, // 上报次数
      timeList: [], // 新增的上报时间集合
      disabled: true,
    };
  },
  watch: {
    timeList: {
      handler() {
        if (this.timeList.length === this.count) this.disabled = true;
      }
    },
  },

  methods: {
    // 保存点击事件
    async submitOk() {
      try {
        this.validateData();

        const time = this.timeList.map(date => date.value).map(date => moment(new Date(date)).format("HH:mm:ss"));

        const result = await setSaveSetting({
          vehicleIds: this.allSelectIds.join(','),
          params: time.join(',')
        });

        const { msg, flag } = result;

        if (flag === 1) this.$message.info(msg);
        this.cancelClick(true);

        // 初始化
        this.timeList = [];
        this.count = 0;
      } catch (err) {
        return this.$message.warning(err);
      }
    },

    // 校验数据
    validateData() {
      const { timeList, count } = this;
      if (timeList.length < count - 1) throw '请选择不少于上报次数的时间';
    },

    // 点击新增上报时间
    addTime() {
      if (this.timeList.length > 9) return;
      this.timeList.push({
        value: new Date('2022-03-28 12:00:00'),
        key: Date.now()
      });
    },

    // 删除点击事件
    remove(item) {
      this.disabled = false;
      var index = this.timeList.indexOf(item);
      if (index !== -1) {
        this.timeList.splice(index, 1);
      }
    },

    // 计数器change 事件
    changeCount(currentValue, oldValue) {
      if (currentValue < this.timeList.length + 1) {
        this.timeList = [];
      }
      this.disabled = false;

      if (this.timeList.length === this.count) this.disabled = true;
    },

    cancelClick(allClose = false) {
      this.$emit('onClose', false, { allClose: allClose });
    },
  }

}
</script>

<style lang="scss" scoped>
.mainCls {
  width: 100%;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
}
.item {
  display: flex;
  margin: 10px;
  & > label {
    line-height: 30px;
    color: #000000;
    font-weight: 600;
    margin: 0 20px 0 0;
  }
  & > span {
    line-height: 30px;
    color: #8e8e8e;
    margin: 0 10px;
  }
}
.delete-icon {
  width: 20px;
  height: 20px;
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 3px 10px;
}
.time-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  .item {
    margin: 10px;
  }
}
.el-input-number {
  width: 180px;
  margin: 0 10px;
}
.el-date-editor {
  width: 155px;
}
</style>