<template>
  <div>
    <Dialog :value="visible"
      width="575px"
      height="680px"
      okText="下一步"
      :submit="submitOk"
      @close="$emit('input',$event)">
      <!-- 标题 -->
      <template #title>
        <span>选择车辆</span>
      </template>

      <div class="mainCls">
        <GroupSearch @select="onSerachSelect"
          inputStr="设备名称"></GroupSearch>

        <div class="treePart">
          <!-- 车组区域 -->
          <div class="groupPartContent">
            <div class="toppart">选择车组</div>
            <GroupTree ref="treeRef"
              class="treeConent"
              defaultExpandAll
              showCheckbox
              useDefault
              :checkStrictly="true"
              @checkChange="onCheckGroup"></GroupTree>
          </div>
          <!-- 车辆列表 -->
          <div class="vehiclePart"
            v-loading="treeLoading">
            <div class="toppart">
              选择车辆
              <!-- <div class="treeHeader">
                <el-button type="text" @click.stop="onCheckAll(true)">全选</el-button>
                <el-button type="text" @click.stop="onCheckAll(false)">取消全选</el-button>
              </div> -->
            </div>

            <el-tree :data="visibleList"
              show-checkbox
              node-key="id"
              default-expand-all
              ref="tree"></el-tree>
            <!-- <VirtualTree
              class="treeConent"
              ref="vehicleRef"
              :showCheckbox="true"
              emptyText="--"
              :data="visibleList"
              :defaultExpandKeys="defaultExpandKeys"
              :defaultCheckedKeys="defaultCheckedKeys"
              :defaultDisabledKeys="defaultDisabledKeys"
              checkOnClickNode
              :rootParentKey="null"
              :indent="26"
              :fieldNames="{
                key:'key',
                parentKey:'parentKey',
                label:'label'
              }"
              @loaded="onTreeLoaded"
              @check-change="onCheckChange"
            >
              <template v-slot="{data}">
                <div class="GVNodeContent">
                  <NodeIcon :nodeType="data.nodeType"></NodeIcon>

                  <div class="GVNodeLabel" :title="data.P">
                    <span>{{data.P}}</span>
                  </div>
                  <el-tag
                    size="mini"
                  >{{['普通设备','有线设备无续航','有线设备可续航','无线设备'][data.gpsType]}}</el-tag>
                </div>
              </template>
            </VirtualTree>-->
          </div>
        </div>
      </div>
    </Dialog>

    <setModal v-model="modalVisible"
      :allSelectIds="allSelectIds"
      @onClose="close" />
  </div>
</template>

<script>
import Dialog from '@/components/Dialog';
import GroupTree from '@/components/GroupTree';
import VirtualTree from '@/components/VirtualTree';
import { loadVehicles } from '@/api/live-monitor-api';
import { diffPatch } from '@/utils/diffPatch';
import { arrayTreeSort, getTreeNodeId, TreeNodeType } from '@/utils/treeHelper.js';
import { sleep } from '@/utils/sleep';
import VehicleStateLabel from '@/components/VehicleStateLabel';
import NodeIcon from '@/components/Icon/NodeIcon.vue';
import GroupSearch from '@/components/GroupSearch';
import setModal from '@/components/DeviceCommandDialog';

export default {
  name: 'DialogGroupVehicle',
  components: {
    Dialog,
    GroupTree,
    VirtualTree,
    VehicleStateLabel,
    NodeIcon,
    GroupSearch,
    setModal
  },
  model: {
    prop: 'visible',
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    // 映射车辆数组
    this.vehicleMap = new Map();
    return {
      // 勾选的车组Id数组
      groupIds: [],
      treeLoading: false,
      checkOnNewGroup: true, // 新增车组的车辆默认是否勾选

      vehicleList: [],

      // 默认勾选
      defaultCheckedKeys: [],
      // 默认展开
      defaultExpandKeys: [],
      visibleList: [],
      innerGroupIds: [],
      modalVisible: false,
      allSelectIds: [], // 选择的所以车辆id
    };
  },
  computed: {
    defaultDisabledKeys() {
      const { vehicleList } = this;

      // return vehicleList.filter(obj => [3, 4].includes(obj.Z) || [0, 1].includes(obj.gpsType))
      return vehicleList.filter(obj => [1, 4, 5].includes(obj.Z) || obj.Z > 5)
        .map(obj => obj.key);
    },
  },
  watch: {
    groupIds(val) {
      this.innerGroupIds = [...val];
    },
    async innerGroupIds(newVal, oldVal) {
      const { vehicleList, vehicleMap } = this;

      const [addIds, updateIds, delIds] = diffPatch(newVal, oldVal, (newId, oldId) => newId === oldId);

      // 新加车组
      if (addIds.length > 0) {
        await this.getVehicles(addIds);
      }

      // 取消车组
      if (delIds.length > 0) {
        this.vehicleList = vehicleList
          .filter(item => !delIds.includes(item.groupId));
        vehicleMap
          .forEach(obj => {
            if (delIds.includes(obj.groupId)) {
              vehicleMap.delete(obj.key);

              // 取消勾选
              this.defaultCheckedKeys = this.defaultCheckedKeys
                .filter(key => key !== obj.key);

              // 取消展开
              this.defaultExpandKeys = this.defaultExpandKeys
                .filter(key => key !== obj.key);
            }
          });
      }

      this.setVisibleList();
    },
    visible(val) {
      if (!val) {
        this.groupIds = [];
      }
    }
  },
  methods: {
    // 车组勾选事件
    async onCheckGroup(data, checked, allCheckeds, node) {
      const { treeRef } = this.$refs;
      if (!treeRef) return;

      if (checked) {

        // 勾选上级节点, 同时勾选所有下级节点
        treeRef
          .getSubNodes(node, true)
          .forEach(subNode => {
            treeRef.setChecked(subNode, true, true);
          });

        await this.$nextTick();
      }

      // 获取所有勾选的节点
      allCheckeds = treeRef.getCheckedNodes();

      this.groupIds = allCheckeds.map(group => group.groupId);
    },
    /**
   * 根据新增的车组, 获取车辆,并将车辆加到列表中
   */
    async getVehicles(groupIds) {
      try {
        const { defaultCheckedKeys, checkOnNewGroup } = this;
        this.treeLoading = true;
        const result = await loadVehicles({ groupIds });

        let addList = [];

        if (result.flag === 1) {

          const { data } = result.obj;
          (data || [])
            .forEach(vehicle => {
              const [vehicleNode] = this.toVehicleNode(vehicle);

              // 是否默认勾选
              if (checkOnNewGroup && !defaultCheckedKeys.some(k => vehicleNode.key === k)) {
                defaultCheckedKeys.push(vehicleNode.key);
                // allSelectIds.push(vehicleNode.V);
              }

              addList.push(vehicleNode);
            });
        }
        if (addList.length > 0) {
          addList = arrayTreeSort(addList, null, { id: 'key', parentId: 'parentKey' });
          this.vehicleList.splice(0, 0, ...addList);
        }

      } catch (err) {
        console.error(err);
      } finally {
        this.treeLoading = false;
      }
    },
    /**
     * 全选/取消全选事件
     */
    onCheckAll(checked = false) {
      const { vehicleRef } = this.$refs;
      if (vehicleRef) {
        vehicleRef.setCheckedAll(checked);
        this.triggerCheckAll(checked);
      }
    },
    // 组装车辆节点
    toVehicleNode(vehicle) {
      const isVideo = vehicle.isSupportMedia;

      const vehicleNode = {
        // 节点类型: 车辆
        nodeType: TreeNodeType.isVehicle,
        isVideo,
        key: getTreeNodeId(vehicle.M, vehicle.V),
        parentKey: getTreeNodeId(vehicle.M),
        vehicleId: vehicle.V,
        groupId: vehicle.M,
        // 设备名称`P`与`label`映射
        label: vehicle.P,
        // 地址字段
        address: '',
        ...vehicle,

        // TODO: 添加必要的响应式字段
      };
      return [
        vehicleNode,
      ];
    },

    setVisibleList() {
      const { vehicleList, filterType } = this;

      if (!vehicleList.length) return this.visibleList = [];

      const list = [
        {
          label: '有线设备无续航',
          id: 2,
          children: vehicleList.filter(item => item.gpsType === 1)
        },
        {
          label: '有线设备可续航',
          id: 3,
          children: vehicleList.filter(item => item.gpsType === 2)
        },
        {
          label: '无线设备',
          id: 4,
          children: vehicleList.filter(item => item.gpsType === 3)
        }];

      this.visibleList = list;
    },

    // tree加载完成, 非准确
    async onTreeLoaded() {
      await this.$nextTick();
      this.treeLoading = false;

      const { currentKey, checkOnNewGroup } = this;
      if (currentKey) {
        this.setCurrentKey(currentKey);
      }

      // 默认勾选车辆
      if (checkOnNewGroup) {
        // NOTE: 等待渲染完成, 不准确
        await sleep(300);
        this.triggerCheckAll(true);
      }
    },
    setCurrentKey(key, intoView = true) {
      this.currentKey = key;
      const { vehicleRef } = this.$refs;
      vehicleRef && vehicleRef.setCurrentKey(key, intoView);
    },

    /**
     * 触发所有已经勾选的事件
     */
    triggerCheckAll(checked = false) {
      const { vehicleRef } = this.$refs;
      if (!vehicleRef) return;
      const allCheckeds = vehicleRef.getCheckedNodes().map(obj => obj.data);
      this.defaultCheckedKeys = allCheckeds.map(obj => obj.key);
      // this.allSelectIds = allCheckeds.map(obj => obj.V);
    },

    /**
     * 节点勾选事件
     */
    onCheckChange(data, checked, node, allCheckeds) {
      this.defaultCheckedKeys = allCheckeds.map(obj => obj.key);
      // this.allSelectIds = allCheckeds.map(obj => obj.V);
    },
    // 车辆搜索事件
    onSerachSelect(data) {
      // 车辆
      if (data.nodeType === TreeNodeType.isVehicle) {
        const key = getTreeNodeId(data.groupId, data.vehicleId);
        this.setCurrentKey(key);
        // 已经加载的车辆直接返回
        if (this.vehicleMap.has(key)) return;
      }

      // 车组, 或未加载的车辆
      const innerGroupIds = [...this.innerGroupIds, data.groupId];
      this.innerGroupIds = innerGroupIds;

      const { treeRef } = this.$refs;
      const { groupId } = data;
      treeRef && treeRef.setCheckedKey(groupId, true);
      treeRef && treeRef.scrollIntoView(groupId);
    },

    submitOk() {
      try {
        const nodeList = this.$refs.tree.getCheckedNodes();
        this.allSelectIds = nodeList.filter(item => !item.children).map(item => item.V);
        console.log(this.allSelectIds);
        this.validateData();

        this.modalVisible = true;
      } catch (error) {
        return this.$message.warning(error);
      }
    },

    validateData() {
      const { allSelectIds } = this;
      if (!allSelectIds.length) throw '请选择车辆';
    },

    close(val, { allClose }) {
      this.modalVisible = val;
      if (allClose) {
        this.$emit('input', false);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mainCls {
  width: 100%;
  padding: 30px;
}
.treePart {
  position: relative;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  margin: 20px 0 0 0;
}
.groupPartContent {
  width: 50%;
  flex: 1;
  overflow: hidden;
  border-right: 1px #ececec solid;
}
.treeConent {
  flex: 1;
  overflow: hidden;
  height: 94%;
}
.vehiclePart {
  width: 50%;
  flex: 1;
  overflow: hidden;
}
.GVNodeLabel {
  position: relative;
  width: calc(100% - 100px);
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.toppart {
  position: relative;
  width: 100%;
  height: 30px;
  background: #e1e1e1;
  border-right: 1px #ffffff solid;
  line-height: 32px;
  padding: 0 0 0 10px;
  color: #1e1e1e;
}
.GVNodeContent {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
}
.treeHeader {
  height: 20px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 5px;
  z-index: 99;
  top: 4px;
}
</style>