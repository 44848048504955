<template>
  <div class="TrackPlaybackSettings">
    <div v-if="trackConfig">
      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">停车时间</label>
          </el-col>
          <el-col :span="15">
            <div class="parking-item">
              <el-input
                v-model="trackConfig.parkingTime" maxlength="9" type="number"
                :disabled="!trackConfig.filterParkingChecked"
                placeholder="请输入内容"
                style="width: 150px"
              ></el-input>
              <span style="font-size: 12px"> &nbsp;分钟</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="config-item" style="margin-left: 37.5%">
        <el-checkbox v-model="trackConfig.filterParkingChecked"
          >过滤停车轨迹</el-checkbox
        >
      </div>
      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">定位数据</label>
          </el-col>
          <el-col :span="15">
            <el-select
              v-model="trackConfig.positioningState"
              placeholder="请选择"
              style="width: 150px"
            >
              <el-option label="全部" :value="1"></el-option>
              <el-option label="过滤不定位轨迹" :value="0"></el-option>
              <el-option label="过滤定位轨迹" :value="2"></el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">默认播放速度</label>
          </el-col>
          <el-col :span="15">
            <el-select
              style="width: 150px"
              v-model="trackConfig.trackPlaySpeed"
            >
              <el-option label="一般" :value="1200"></el-option>
              <el-option label="快" :value="3200"></el-option>
              <el-option label="慢" :value="200"></el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // `name`与路由保持一致
  name: "TrackPlaybackSettings",
  props: {
    config: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    trackConfig() {
      const { config } = this;
      if (config) return config.trackConfig;
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.TrackPlaybackSettings {
  height: 100%;
  font-size: 14px;
  .config-item {
    flex-grow: 1;
    margin: 15px 0;
    position: relative;
    line-height: 26px;
  }
  .parking-item {
    display: inline-block;
    line-height: 30px;
    .el-input {
      width: 180px;
    }
  }
}
</style>