<template>
  <div class="RealTimePositioningSettings">
    <div v-if="realTimeConfig">
      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">车辆信息检测时间</label>
          </el-col>
          <el-col :span="15">
            <el-input-number
              class="input-number-mini"
              step-strictly
              v-model="realTimeConfig.updateVehicleTime"
              size="mini"
              controls-position="right"
              :step="1"
              :min="15"
              :max="1000000000"
              style="width: 150px"
            ></el-input-number>
            <span style="font-size: 12px">&nbsp;(秒,最小15秒)</span>
          </el-col>
        </el-row>
      </div>
      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">车辆聚合最小聚合数</label>
          </el-col>
          <el-col :span="15">
            <el-input-number
              class="input-number-mini"
              step-strictly
              v-model="realTimeConfig.vehicleMinClusterNum"
              size="mini"
              controls-position="right"
              :step="1"
              :min="2"
              :max="1000"
              style="width: 150px"
            ></el-input-number>
            <span style="font-size: 12px">&nbsp;(2~1000)</span>
          </el-col>
        </el-row>
      </div>

      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">启用到期提醒</label>
          </el-col>
          <el-col :span="15">
            <el-checkbox v-model="realTimeConfig.autoNoticeExpired"></el-checkbox>
          </el-col>
        </el-row>
      </div>

      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">定位时间段设置</label>
          </el-col>
          <el-col :span="15">
            <el-button type="primary" @click="setAction">开始设置</el-button>
          </el-col>
        </el-row>
      </div>
    </div>

    <GroupVehicle v-model="dialogModal" />
  </div>
</template>

<script>
import GroupVehicle from '@/components/DialogGroupVehicle';

export default {
  // `name`与路由保持一致
  name: "RealTimePositioningSettings",
  components: {
    GroupVehicle
  },
  props: {
    config: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialogModal: false,
    };
  },
  computed: {
    realTimeConfig() {
      const { config } = this;

      if (config) return config.realTimeConfig;

      return null;
    },
  },

  methods: {
    // 开始调试点击事件
    setAction() {
      this.dialogModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.RealTimePositioningSettings {
  height: 100%;
  font-size: 14px;
  .config-item {
    flex-grow: 1;
    margin: 15px 0;
    position: relative;
    line-height: 26px;
  }
}
</style>