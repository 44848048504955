<template>
  <div class="VideoPlaybackSettings">
    <div v-if="vodConfig">
      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">定时关闭视频</label>
          </el-col>
          <el-col :span="15">
            <el-input-number
              class="input-number-mini"
              size="mini"
              v-model="vodConfig.videoCloseTime"
              :min="0"
              :max="1440"
              controls-position="right"
              style="width: 150px"
            ></el-input-number>
            <span style="font-size: 12px"> &nbsp;(分钟,0表示从不)</span>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // `name`与路由保持一致
  name: "VideoPlaybackSettings",
  props: {
    config: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    vodConfig() {
      const { config } = this;
      if (config) return config.vodConfig;
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.VideoPlaybackSettings {
  height: 100%;
  font-size: 14px;
  .config-item {
    flex-grow: 1;
    margin: 15px 0;
    position: relative;
    line-height: 26px;
  }
}
</style>