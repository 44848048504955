<template>
  <div class="RealTimeVideoSettings">
    <div v-if="realTimeConfig">
      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">视频窗口布局</label>
          </el-col>
          <el-col :span="15">
            <el-select v-model="realTimeConfig.videoGrid" style="width: 150px">
              <el-option
                v-for="gridObj in videoGridOptions"
                :key="gridObj.value"
                :value="gridObj.value"
                :label="gridObj.label"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">定时关闭视频</label>
          </el-col>
          <el-col :span="15">
            <el-input-number
              class="input-number-mini"
              size="mini"
              v-model="realTimeConfig.videoCloseTime"
              :min="0"
              :max="1440"
              controls-position="right"
              style="width: 150px"
            ></el-input-number>
            <span style="font-size: 12px"> &nbsp;(分钟,0表示从不)</span>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
const videoGridOptions = [
  { value: 1, label: "1行x1列" },
  { value: 4, label: "2行x2列" },
  { value: 9, label: "3行x3列" },
  { value: 16, label: "4行x4列" },
  { value: 25, label: "5行x5列" },
];

export default {
  // `name`与路由保持一致
  name: "RealTimeVideoSettings",
  props: {
    config: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      videoGridOptions,
    };
  },
  computed: {
    realTimeConfig() {
      const { config } = this;
      if (config) return config.realTimeConfig;
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.RealTimeVideoSettings {
  height: 100%;
  font-size: 14px;
  .config-item {
    flex-grow: 1;
    margin: 15px 0;
    position: relative;
    line-height: 26px;
  }
}
</style>