<template>
  <div class="stateSpan">
    <div :class="outerCls">{{getStateLabel }}</div>
  </div>
</template>

<script>
import { color } from 'echarts';

/**
 * 车辆状态显示文本
 */

export default {
  name: 'VehicleStateLabel',
  props: {
    // 车组状态
    state: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    getStateLabel() {
      const { state } = this;
      // if (state === 0) return '离线';
      // if (state === 1) return '行驶';
      // if (state === 2) return '停车';
      // if (state === 3) return '从未上线';
      // if (state === 4) return '过期';
      // if (state === 0) return '怠速';
      // if (state === 1) return '行走';
      // if (state === 2) return '作业';
      // if (state === 3) return '通电';
      // if (state === 4) return '熄火';
      // if (state === 5) return '离线';
      // if (state === 6) return '从未上线';

      // if (state === 0) return '怠速';
      // if (state === 1) return '行驶';
      // if (state === 2) return '作业';
      // if (state === 3) return '通电';
      // if (state === 4) return '熄火';
      // if (state === 5) return '离线';
      
      if (state === 0) return '停工';
      if (state === 1) return '开工';
      if (state === 5) return '离线';
      return "从未上线";
      
      return '未知';
    },
    outerCls() {
      const { state } = this;
      // if ([1, 2].includes(state)) return 'Online';
      // if ([4].includes(state)) return 'Offline';
      // if ([0].includes(state)) return 'yellow';
      // if ([3].includes(state)) return 'blue';
      let color = 'NeverOnline';
      switch (state) {
        case 0:
          color = 'yellow';
          break;
        case 1:
          color = 'Online';
          break;
        case 2:
          color = 'Online';
          break;
        case 3:
          color = 'blue';
          break;
        case 4:
          color = 'Offline';
          break;
        case 5:
          color = 'NeverOnline';
          break;
      };
      return color;
    }
  }
}
</script>

<style lang="scss" scoped>
.stateSpan {
  min-width: 40px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>