<template>
  <div class="VideoPollingSettings">
    <div v-if="videoPollingConfig">
      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">默认通道</label>
          </el-col>
          <el-col :span="15">
            <el-select
              v-model="videoPollingConfig.pollingChannels"
              multiple
              collapse-tags
              style="width: 150px"
            >
              <el-option
                v-for="channelObj in channelOptions"
                :key="channelObj.value"
                :value="channelObj.value"
                :label="channelObj.label"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">视频布局</label>
          </el-col>
          <el-col :span="15">
            <el-select
              v-model="videoPollingConfig.videoGrid"
              style="width: 150px"
            >
              <el-option
                v-for="gridObj in videoGridOptions"
                :key="gridObj.value"
                :value="gridObj.value"
                :label="gridObj.label"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">默认播放时长</label>
          </el-col>
          <el-col :span="15">
            <el-input-number
              v-model="videoPollingConfig.playTime"
              :min="5"
              :max="videoPollingConfig.totalPlayTime * 60"
              :step="1"
              controls-position="right"
              style="width: 150px"
            ></el-input-number>
            <span style="font-size: 12px"> &nbsp;(秒)</span>
          </el-col>
        </el-row>
      </div>
      <div class="config-item">
        <el-row>
          <el-col :span="9" style="text-align: right; padding-right: 5%">
            <label style="text-align: left">总时长</label>
          </el-col>
          <el-col :span="15">
            <el-input-number
              v-model="videoPollingConfig.totalPlayTime"
              :min="5"
              :step="1"
              controls-position="right"
              style="width: 150px"
            ></el-input-number>
            <span style="font-size: 12px"> &nbsp;(分钟)</span>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
// 通道
const channelOptions = [
  { value: 1, label: "通道1" },
  { value: 2, label: "通道2" },
  { value: 3, label: "通道3" },
  { value: 4, label: "通道4" },
  { value: 5, label: "通道5" },
  { value: 6, label: "通道6" },
  { value: 7, label: "通道7" },
  { value: 8, label: "通道8" },
];

// 每次播放的数量
const videoGridOptions = [
  { value: 1, label: "1行x1列" },
  { value: 4, label: "2行x2列" },
  { value: 9, label: "3行x3列" },
  { value: 16, label: "4行x4列" },
  { value: 25, label: "5行x5列" },
];

import { mapState, mapActions } from "vuex";

export default {
  // `name`与路由保持一致
  name: "VideoPollingSettings",
  props: {
    config: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      channelOptions,
      videoGridOptions,
    };
  },
  computed: {
    ...mapState(["userConfig"]),
    videoPollingConfig() {
      const { config } = this;
      if (config) return config.videoPollingConfig;
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.VideoPollingSettings {
  height: 100%;
  font-size: 14px;
  .config-item {
    flex-grow: 1;
    margin: 15px 0;
    position: relative;
    line-height: 26px;
  }
}
</style>